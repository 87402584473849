<style type="scss">
.thumbnails {
  display: flex;
  height: 100px;
  .thumbnail-container {
    width: 100px;
    max-width: 100px;
  }


}
</style>
<template>
  <v-row class="justify-center align-center">
    <v-col
      cols="12"
    >
      <v-card flat>
        <div class="pa-5">
          <!--          <h6 class="mb-4 text-h5">
            {{ $t('Photos de l\'article') }}
          </h6>-->
          <v-row class="justify-center ">
            <v-col

              v-if="!product.images.length"
              cols="auto"

            >
              <v-img   v-if="currentImage != null" :src="currentImage.image" contain height="300"
                       @click="show"
              />
              <file-upload
                v-else
                :url="`/product/store_photo/${product.id}`"
                :event="onData"
                :multiple="true"
                inputname="file[]"
              >
                <template #input>
                  <v-card outlined flat >
                    <v-card-text>
                      <v-row class="justify-center align-center fill-height">
                        <v-col cols="12" class="mx-auto justify-center align-center">
                          <p class="text-center display-1">{{ $t("Aucune image n'a été sélectionnée. Veuillez ajouter des images")}}</p>

                        </v-col>
                        <v-col cols="12" class="d-flex justify-center align-center">
                          <v-icon size="100" class="mx-auto" color="primary">mdi-image-plus</v-icon>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </template>
              </file-upload>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <draggable v-model="product.images" class=" justify-center row align-center gutters"  draggable=".item" group="people"
                       @end="onEnd"
              >
                <v-col :key="thumb.id" cols="4" sm="4" md="3" lg="2" xl="2" v-for="(thumb, index) in product.images || []"
                       class="border-grey  h-100 item d-flex flex-column justify-center  cursor-grap"
                        :class="{'primary': index === currentIndex}"
                       style="height: 100%"
                >
                  <v-img :src="thumb.thumb" cover  style="width: 100%; height: 100%; max-height: 100%;"  />
                  <v-card-actions class="py-0">
                    <v-btn x-small  depressed text :color="`${currentIndex===index?'white':'info'}`" out class="mt-2" @click="setImage(index,thumb)">
                      <v-icon>mdi-magnify</v-icon>
                      <!--{{ $t("Close") }}-->
                    </v-btn>
                    <v-spacer></v-spacer>

                    <v-dialog
                      v-model="thumb.showDialog"
                      width="300"
                      class="overflow-hidden transparent"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- Button to Open the Dialog -->
                        <v-btn x-small  depressed text v-bind="attrs" v-on="on" :color="`${currentIndex===index?'white':'danger'}`" out class="mt-2" >
                          <v-icon>mdi-close</v-icon>
                          <!--{{ $t("Close") }}-->
                        </v-btn>
                      </template>
                      <v-card
                        width="300"

                        class="overflow-hidden"
                      >
                        <v-card-title>{{ $t("Confirmer")}}</v-card-title>
                        <v-card-text>
                          <p>{{ $t("Êtes-vous sûr de vouloir supprimer cet élément ?")}}</p>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn outlined small @click.stop="thumb.showDialog = false">{{ $t("Cancel")}}</v-btn>
                          <v-spacer />
                          <v-btn depressed color="primary" small
                                 @click.stop="remove(index, thumb)"
                          >{{ $t("Continuer")}}</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>


                  </v-card-actions>


                </v-col>
                  <v-col v-if="product.images.length"  cols="4" sm="4" md="2" lg="2" class="h-100 p-0"  style="height: 100%;">
                    <file-upload
                      :url="`/product/store_photo/${product.id}`"
                      :event="onData"
                      :multiple="true"
                      inputname="file[]"
                    >
                      <template #input>
                        <div  class="border-grey h-100  align-center justify-center d-flex" style="height: 100px; width: 70px;">
                          <v-icon size="50" color="primary">
                            mdi-image-plus
                          </v-icon>
                        </div>
                      </template>
                    </file-upload>
                  </v-col>
              </draggable>
            </v-col>
          </v-row>
        </div>
        <v-snackbar v-model="isSnackbarBottomVisible">
          {{ snackBarMessage }}

          <template v-slot:action="{ attrs }">
            <v-btn
              :color="snackBarColor"
              text
              v-bind="attrs"
              @click="isSnackbarBottomVisible = false"
            >
              {{ $t("Fermer") }}
            </v-btn>
          </template>
        </v-snackbar>
        <v-dialog
          v-model="loading"
          persistent
          width="70"
          class="overflow-hidden transparent"
        >
          <v-card
            width="70"
            height="70"
            class="overflow-hidden"
          >
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="fill-height align-self-center"
            >
              <v-col
                cols="12"
                class="text-center"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="mx-auto"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
        <slot name="actions" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { mapGetters, mapActions, mapState } from 'vuex'
  import 'viewerjs/dist/viewer.css'
  import VueViewer, { api as viewerApi } from 'v-viewer'

  import { i18n } from '../../plugins/i18n'
  import draggable from 'vuedraggable'
  import FileUpload from "@/components/file_upload.vue";


  export default {
    components: {
      FileUpload,
      draggable,
    },
    props: {
      value: { type: [Object, Array], default: () => { return { images: [] } } },
    },
    data () {
      return {
        currentImage: null,
        currentIndex: null,
        step: 0,
        tab: null,
        showWarning: false,
        productForm: null,
        validAddressForm: false,
        loading: false,
        loading_points: false,
        loading_cities: false,
        create_new: false,
        security_tax: 0,
        commission_minimal: 0,
        formData: null,
        fileList: [],
        donate: 0,
        details: {},
        form: {},
        usercountry: 0,
        tvas: [],
        transporters: [],
        system_transporters: [],
        shipment_methods: [],
        payment_methods: [],
        relay_points: [],
        packages: [],
        images: [],
        medias: [],
        package: {},
        errors: {
          firstname: false,
          lastname: false,
          email: false,
          phone: false,
          country: false,
          address: false,
          zip: false,
          city: false,
        },

        errorMessages: { name: 'Est requis' },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse est requise.')],
        introRules: [v => !!v || i18n.t('L\'introduction est requise.')],
        nameRules: [v => !!v || i18n.t('Le Nom du produit est requise.')],
        publicRules: [v => !!v || i18n.t('Le Prix public est requise.')],
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        radios: null,
        radiosTwo: null,
        cart: [
        ],
        product: {
          images: [],
        },
        order: {
          user: {
            country_code: null,
          },
          country_code: null,
          city: null,
          post_code: null,
        },
        addresses: [],
        deliveryaddress: [],
        snackBarMessage: '',
        snackBarColor: null,
        isSnackbarBottomVisible: false,

      }
    },
    computed: {
      ...mapState({
        user: 'user',
        displayNotification: 'display_notification',
      }),
      cartTotal () {
        let total = 0
        this.getCartProducts.forEach(product => {
          total += product.amount * product.qty
        })
        return total
      },
      backButtonLabel () {
        return this.$t('Précédent')
      },
      nextButtonLabel () {
        if (this.step === 0) {
          return this.$t('Suivant')
        }
        if (this.step === 1) {
          return this.$t('Procéder au Categories')
        }
        if (this.step === 2) {
          return this.$t('Procéder a la publication')
        }
        return this.$t('Suivant' + this.step)
      },
      finishButtonLabel () {
        if (this.product && this.product.active) {
          return this.$t('Deactiver')
        }
        return this.$t('Publier')
      },
      productImages () {
        return this.product.images.map((e) => { return { ...e, path: e.image } })
      },
      productMedias () {
        return this.product.images || []
      },
    },
    mounted () {

    },
    created () {
      this.product = this.value || { images: [] }
      this.product.images = this.product.images.map((e) => {
        return {
          ...e,
          showDialog: false,
        }
      })
      this.images = this.productImages
      if( this.product.images.length) {
      //  this.setImage(0, this.product.images[0])
      }

    },
    methods: {
      show () {

        const $viewer = viewerApi({
          images: this.product.images || [],
          options: {
            toolbar: true,
            url: 'image',
            initialViewIndex: this.currentIndex || 0
          },
        })
      },
      setImage(index, image) {
        //this.currentIndex = index
       // this.currentImage = image
        const $viewer = viewerApi({
          images: this.product.images || [],
          options: {
            toolbar: true,
            url: 'image',
            initialViewIndex: index || 0
          },
        })
      },
      remove(index, image) {
        image.showDialog = false;
        this.product.images.splice(index,1)
        if(index > 0) {
         // this.setImage(index-1,this.product.images[index-1])
        }else{
          if(this.product.images.length) {
           // this.setImage(0,this.product.images[0])
          }else{
            this.currentImage = null
            this.currentImage = null
          }
        }
        this.orderImages()
      },
      onRemoved(index, element){
       this.orderImages()
      },
      onEnd(evt){
       this.orderImages()
       // this.setImage(this.currentIndex, this.product.images[this.currentIndex])
      },
      orderImages(){
        axios.post(`/product/order_photos/${this.product.id}`, { images: this.product.images.map((e) => e.id) })
          .then(response => {

          })
          .catch(error => {

          }).then(() => {
          this.loading = false
        })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
      uploadImageSuccess (formData, index, fileList) {
        this.formData = formData
        this.fileList = fileList

        this.submitImage(formData, false, index)
        // const data = new FormData()
        /* for (const file of fileList) {
            this.product.photos.push(file)
           // data.append(this.inputname, file, file.name)
          } */

        // return data
        // console.log('data', formData, index, fileList)
        // Upload image api
        // axios.post('http://your-url-upload', formData).then(response => {
        //   console.log(response)
        // })
      },
      beforeRemove (index, done, fileList) {
        console.log('index', index, fileList)
        var r = confirm('Êtes-vous sûr de supprimer l\'image ?')
        if (r == true) {
          if (this.product && this.product.id && this.product.media && this.product.media.length > 0) {
            this.product.images.splice(index, 1)
            this.product.media.splice(index, 1)
            // eslint-disable-next-line no-undef
            axios.post(`/product/order_photos/${this.product.id}`, { images: this.product.images.map((e) => e.id) }).then((res) => {})
          }
          done()
        } else {
        }
      },
      editImage (formData, index, fileList) {
        this.formData = formData
        this.fileList = fileList

        this.submitImage(formData, false, index)
        console.log('edit data', formData, index, fileList)
      },
      onData (evt, data) {
        switch (evt) {
          case 'upload':
            this.upload(data)
            break;
          default:
            return;
        }
      },
      upload ( images) {
        //console.log("evt",image)
        this.product.images.push(...images.map((image) => {
          return {
            ...image,
            showDialog: false,
            img: image.image || require('@/assets/images/icons/picture.svg'),
            image: image.image || require('@/assets/images/icons/picture.svg'),
            thumb: image.thumb || require('@/assets/images/icons/picture.svg'),
          }
        }))
        //this.setImage(this.product.images.length-1, this.product.images[this.product.images.length-1])
      },
      submitImage (form, saved = false, index = -1) {
        if (!this.product.id && this.form) return
        // const url = "admin/customer/photo/" + this.customer.id;
        // upload data to the server
        //    this.currentStatus = STATUS_SAVING;
        // this.$emit('input', null);

        this.formData = null

        this.loading = true
        axios.post(`/product/store_photo/${this.product.id}`, form)
          .then(response => {
            // this.product.images.push(response.data)
            // this.product.media = this.product.media || []
            // this.product.media.push(response.data)
            if(index >= 0) {
              this.product.images[index] = {
                ...response.data,
                img: response.data.image || require('@/assets/images/icons/picture.svg'),
                image: response.data.image || require('@/assets/images/icons/picture.svg'),
                thumb: response.data.thumb || require('@/assets/images/icons/picture.svg'),
              }
            }
            if (saved) {
              this.toast(this.$t('Produit enregistrer avec success'), 'success')
            }
          })
          .catch(error => {

          }).then(() => {
          this.loading = false
        })
      },

      getCities (code) {
        var country = this.getItem(this.countries, 'code', code)
        var _this = this
        // this.order.address.city_id = null;

        if (!country.cities || country.cities.length === 0) {
          this.loading_cities = true
          axios.get('/countries/' + code).then((response) => {
            _this.loading_cities = false
            country.cities = response.data
            Vue.set(_this.countries, _this.getIndex(_this.countries, 'code', code), country)
          })
        }
        ;
        return country.cities || []
      },
      cityChanged (event) {
        // this.relaypoints(0);

      },
      zipChanged (event) {
        // this.relaypoints(0);

      },
      close () {
        $('#paymentbox').modal('hide')
        $('#paymentbox').find('.modal-body').html('')
      },
      getItem (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return items[i]
          }
        }
        return false
      },

      getIndex (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return i
          }
        }
        return -1
      },
    },
  }
</script>
