<template>
  <!-- card-hover-shadow  -->
  <base-card
    style="height: 100%;min-height: 100%"
    class="d-flex flex-column justify-space-between"
  >
    <div class="d-flex  flex-column flex-grow-1 flex-1 justify-start">
      <v-card-title class="py-1  mt-0 px-1 px-md-3 ">
        <vendor-link
          :name-length="$vuetify.breakpoint.xsOnly?20:30"
          :vendor="{...item.user,
                    path: `/shops/${item.user.id || ''}`,
          }"
        />
      </v-card-title>
      <product-media
        v-model="item"
        :height="$vuetify.breakpoint.smAndDown?($vuetify.breakpoint.xsOnly?150:200):250"
        width="100%"
        :product="item"
        :show-modal="true"
        :show-thumbnails="false"
      />
      <div class="py-1 px-1 px-md-3 d-flex justify-content-between align-end mt-n5 ">
        <div class="flex-grow-1 my-3 mb-md-3 mb-0">
          <router-link
            :to="`/products/${item.slug }`"
            class="text-decoration-none"
          >
            <!--          <h6 class="mb-0 black&#45;&#45;text text&#45;&#45;darken-4 font-weight-bold text-truncate w-100 text-body-2 text-lg-body-1">-->
            <h6 class="mb-1 black--text text--darken-4 font-weight-bold  w-100 text-body-2 text-lg-body-1">
              {{ shortHtml(contentText, $vuetify.breakpoint.mdAndUp?50:($vuetify.breakpoint.xsOnly?17:23)) }}
            </h6>
          </router-link>
          <div
            v-if="item.reviews_avg_rating > 0"
            class="d-flex mb-1"
            :style="{height: $vuetify.breakpoint.smAndDown?'12px':'22px'}"
          >
            <v-rating
              v-if="item.reviews_avg_rating > 0"
              :value="item.reviews_avg_rating"
              color="amber"
              background-color="amber"
              dense
              half-increments
              readonly
              :size="$vuetify.breakpoint.smAndDown?10:14"
            />

            <div
              v-if="item.reviews_avg_rating > 0"
              class="grey--text ml-1"
            >
              {{ item.reviews_avg_rating }}
            </div>
          </div>
          <div class="d-flex flex-md-row ">
            <h6 class="primary--text mr-2 mb-0">
              € {{ item.price_without_tax }}
            </h6>
            <h6
              v-if="item.reduction_percentage"
              class="gray--text lighten-4 text-decoration-line-through mb-0"
            >
              € {{ item.price_with_tax }}
            </h6>
          </div>
        </div>
        <!-- btn cart  -->
        <!-- <slot name="cardCart"></slot> -->
        <!--
              <div class="d-flex flex-column p-absolute add-to-cart-container" >
                <v-btn
                  :class="counter <= 0 ? 'd-none': 'd-block'"
                  class=" rounded "
                  outlined
                  fab
                  x-small
                  tile
                  color="primary"
                  @click="$emit('cartRemove')"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <div
                  :class="counter <= 0 ? 'd-none': 'd-block'"
                  class="text-center font-weight-bold"
                >
                  {{ counter }}
                </div>
                <v-btn
                  :disabled="item.quantity <= item.qty"
                  class="rounded"
                  outlined
                  fab
                  x-small
                  tile
                  color="primary"
                  @click="$emit('cartAdd')"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
        -->
      </div>
    </div>
    <div class="px-2 py-2 flex-grow-0 flex-shrink-1">
      <v-row>
        <v-col
          cols="6"
        >
          <add-to-cart-button :product="item" />
        </v-col>
        <v-col
          cols="6"
        >
          <v-btn
            block
            small
            :to="`/products/${item.slug }`"
            outlined
            color="primary"
            class="text-capitalize flex-grow-0 flex-shrink-1"
          >
            {{ $t("Voir") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </base-card>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import VendorLink from '../VendorLink'

  import ProductMedia from '../products/ProductMedia'

  import AddToCartButton from '../products/AddToCartButton.vue'

  export default {
    components: { AddToCartButton, ProductMedia, VendorLink },
    props: {
      product: {
        type: Object,
        default: () => {},
      },
      btnText: {
        type: String,
        default: '',
      },
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
      counter: {
        type: Number,
        default: 0,
      },
      contentImg: String,
      contentText: String,
      amount: {
        type: Number,
        default: 200,
      },
      contentRating: {
        type: Number,
        default: 4.5,
      },
    },
    computed: {
      ...mapGetters(['getProducts']),

    },
    data () {
      return {
        dialog: false,
        item: {},
      }
    },
    created () {
      this.item = this.product
      this.item.qty = this.getProducts.find((el) => el.id === this.product.id).qty || 0
    },
    methods: {
      ...mapActions(['addCart']),

    },
  }
</script>
<style lang="scss" scoped>

    .br-t-8 {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  .card-hover {
    &:hover {
      .card-hover-icon {
        display: block;
      }
    }
    .card-hover-icon {
      display: none;
    }
  }
  .add-to-cart-container {
    right: 14px;
  }
    @media (max-width: 414px) {
      .add-to-cart-container {
        right: 6px;
      }
    }
    @media (max-width: 768px) {
      .add-to-cart-container {
        right: 6px;
      }
    }

</style>
