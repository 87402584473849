<template>
  <div class="product-slider p-relative grey lighten-5 " style="flex: auto;">
    <div
      class="card-hover transparent"
      :class="cardHoverShadow ? 'card-hover-shadow' : '' "
    >
      <v-chip
        v-if="product.promo && product.reduction_percentage && product.promo.flash_price"
        class="ma-2 p-absolute z-1 "
        color="primary"
        :small="$vuetify.breakpoint.smAndUp"
        :x-small="$vuetify.breakpoint.xsOnly"
      >
        {{ $t('Vente Flash') }}
      </v-chip>
      <v-chip
        v-else-if="product.reduction_percentage"
        class="ma-2 p-absolute z-1 "
        color="primary"
        :small="$vuetify.breakpoint.smAndUp"
        :x-small="$vuetify.breakpoint.xsOnly"
      >
        -{{ product.reduction_percentage }}%
      </v-chip>
<!--      <div class="card-hover-icon">
        <div class="d-flex flex-column p-absolute right-0 z-1 mr-2 mt-1 card-hover-icon">-->
<!--      <div class="">
        <div class="d-flex flex-column p-absolute right-0 z-1 mr-2 mt-1 ">
          <product-modal
            v-if="showModal"
            :product="product"
          />
          &lt;!&ndash; end dialog end modal  &ndash;&gt;
          <v-btn
            v-if="product.liked"
            icon
            color="secondary"
            @click.stop="react"
          >
            <v-icon>mdi-heart</v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
            color="secondary"
            @click.stop="react"
          >
            <v-icon>mdi-heart-outline</v-icon>
          </v-btn>
        </div>
      </div>-->
      <div class="d-flex flex-column p-absolute right-0 z-1 mr-2 mt-1 shade rounded justify-center align-center ">
        <product-modal
          v-if="showModal"
          :product="product"
        />
        <!-- end dialog end modal  -->
        <v-btn
          v-if="$store.state.login"
          icon
          color="white"
          @click.stop="react"
          class="mb-0"
        >
          <v-icon v-if="product.liked">mdi-heart</v-icon>
          <v-icon v-else>mdi-heart-outline</v-icon>
        </v-btn>
        <span v-if="product.product_likes_count > 0" class="body-1 white--text shades--text ">{{ product.product_likes_count || 0 }}</span>
      </div>
      <template v-if="!product.images || product.images.length === 0">
        <router-link
          v-if="showModal"
          :to="`/products/${product.slug }`"
        >
          <v-img
            class="mx-auto br-t-8"
            :width="width"
            :height="height"
            :alt="product.name"
            :title="product.name"
            :lazy-src="product.thumb"
            :src="product.medium || '/img/logo.png'"
          />
        </router-link>
        <v-img
          v-else
          class="mx-auto "
          :width="width"
          :height="height"
          :alt="product.name"
          :title="product.name"
          :lazy-src="product.thumb"
          :src="product.medium || '/img/logo.png'"
        />
      </template>
      <template v-else>
        <carousel
          :images="product.images"
          :starting-image="0"
          :auto-slide-interval="showThumbnails?5000:0"
          :show-thumbs="showThumbnails && product.images"
          :width="width"
          :height="height"
          :product="product"
        />
        <!--        <VueSlickCarousel
          ref="c1"
          :fade="true"
          :arrows="false"
          :as-nav-for="$refs.c2"
          :focus-on-select="true"
        >
          <div
            v-for="(item, index) in product.images"
            :key="index"
          >
            <router-link
              v-if="showModal"
              :to="`/products/${product.slug }`"
            >
              <v-img
                class="mx-auto br-t-8"
                :width="width"
                :height="height"
                :src="item[field]"
              />
            </router-link>
            <v-img
              v-else
              class="mx-auto "
              :width="width"
              :height="height"
              :src="item[field]"
            />
          </div>
        </VueSlickCarousel>-->
      </template>
      <div
        v-if="source === 'many'"
        class="p-absolute z-1 ma-2 clock-container"
      >
        <clock
          v-if="product.promo"
          :now="product.promo.now"
          :future="product.promo.dateend"
          :start="product.promo.datestart"
        />
      </div>
    </div>
    <!--    <v-row justify="center"  v-if="showThumbnails && product.images">
      <v-col cols="12" sm="6" md="7">

        <VueSlickCarousel

          ref="c2"
          class="product-slider-click mt-2"
          :as-nav-for="$refs.c1"
          :slides-to-show="4"
          :focus-on-select="true"
        >
          <div
            v-for="(item, index) in product.images"
            :key="index"
          >
            <v-avatar
              class="white rounded"
              tile
              :size="thumbSize"
            >
              <img
                :src="item.thumb"
                alt=""
                :width="thumbSize"
              >
            </v-avatar>
          </div>
          <template #prevArrow="arrowOption">
            <v-btn
              class="mx-2 slick-arrow prev slick-prev"
              fab
              dark
              small
              color="secondary"
            >
              <v-icon dark>
                {{ arrowOption }}
                mdi-arrow-left
              </v-icon>
            </v-btn>
          </template>
          <template #nextArrow="arrowOption">
            <v-btn
              class="mx-2 slick-arrow next slick-next"
              fab
              dark
              small
              color="secondary"
            >
              <v-icon dark>
                {{ arrowOption }}
                mdi-arrow-right
              </v-icon>
            </v-btn>
          </template>
        </VueSlickCarousel>
      </v-col>
    </v-row>-->
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import ProductModal from './ProductModal'
  import Clock from '../Clock.vue'
  import Carousel from '../Carousel.vue'
  import Login from '../../views/Login.vue'

  export default {
    name: 'ProductMedia',
    components: { Login, Carousel, Clock, ProductModal },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
      showModal: {
        type: Boolean,
        default: false,
      },
      showThumbnails: {
        type: Boolean,
        default: false,
      },
      source: {
        type: String,
        default: 'many',
      },
      thumbSize: {
        type: String,
        default: '87',
      },
      width: {
        type: String,
        default: '250',
      },
      height: {
        type: String,
        default: 'auto',
      },
      field: {
        type: String,
        default: 'medium',
      },
      cardHoverShadow: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters(['getProducts']),

    },
    data () {
      return {
        tab: null,
        product: {},
        medias: [
          {
            thumb: require('@/assets/images/products/flash-1.png'),
            big: require('@/assets/images/products/flash-1.png'),
            id: 'Sneakers',
          },
          {
            thumb: require('@/assets/images/products/flash-2.png'),
            big: require('@/assets/images/products/flash-2.png'),
            id: 'Watch',
          },
          {
            thumb: require('@/assets/images/products/flash-3.png'),
            big: require('@/assets/images/products/flash-3.png'),
            id: 'Mobile',
          },

        ],

      }
    },
    watch: {
      value: {
        handler (val) {
          this.product = this.value
        },
        deep: true,
      },
    },
    created () {
      this.product = this.value || { images: [] }
    },
    methods: {
      ...mapActions(['addCart']),
      react () {
        this.product.product_likes_count =    this.product.product_likes_count || 0
        if(this.product.liked) {
          this.product.liked = false
          this.product.product_likes_count -=1
        } else {
          this.product.liked = true
          this.product.product_likes_count +=1

        }

        axios.post('/products/like/' + this.product.id, {}).then(response => {

        })
      },

    },
  }
</script>
<style lang="scss" scoped>

    .br-t-8 {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  .card-hover {
    &:hover {
      .card-hover-icon {
        display: block;
      }
    }
    .card-hover-icon {
      display: none;
    }
  }
    .product-slider {
      .product-slider-click {
        display: flex;
        justify-content: center;

        ::v-deep .slick-slide {
          width: auto !important;
          margin-right: 16px;
          border: 1px solid rgb(218, 225, 231);
          border-radius: 5px;
        }
        ::v-deep .slick-current {
          border: 1px solid #D23F57;
          border-radius: 5px;
        }
      }
    }
    .clock-container {
      width: auto;
      bottom: 0;
    }
</style>
